import { Fragment, ReactElement, useEffect, useState } from 'react'
import { NavLink, Outlet as ComponentOfCurrentRoute, useLocation } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import Person from '@mui/icons-material/Person'
import CustomerIcon from '@mui/icons-material/Business'
import LicenseIcon from '@mui/icons-material/VpnKey'
import BillingIcon from '@mui/icons-material/Payment'
import LogoutIcon from '@mui/icons-material/PowerSettingsNew'
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop'
import SettingsIcon from '@mui/icons-material/Settings'
import { Theme } from '@mui/material/styles'

import { INITIAL_PATH, Path } from '../routes/path'
import { NavigationState } from './common/hooks/hook-stateful-navigate'
import { useAuth } from './login/Auth'
import netiLogoWhite from '../assets/netinsight-logo-color@3x.png'
import { BuildInfo, Role } from 'common-billing-server/types'
import { isRoleAuthorized } from 'common-billing-server/role'
import { useBillingApi } from 'src/api/billing/billing-context'
import ListItemButton from '@mui/material/ListItemButton'
const drawerWidth = 240
const styles = {
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
        },
    },
    drawerItem: {
        '&:hover, &:focus': {
            background: (theme: Theme) => theme.palette.background.default,
        },
    },
}

interface DrawerItem {
    title: string
    icon: ReactElement
    path: Path
    minimumAllowedRole?: Role
    addDivider?: boolean
}

const topDrawerItems = [
    { title: 'Customers', icon: <CustomerIcon />, path: Path.customers, minimumAllowedRole: Role.licenseAdmin },
    { title: 'Licenses', icon: <LicenseIcon />, path: Path.licenses, minimumAllowedRole: Role.licenseAdmin },
    { title: 'Installations', icon: <InstallDesktopIcon />, path: Path.installations },
    { title: 'Billing', icon: <BillingIcon />, path: Path.billing },
    { title: 'Users', icon: <Person />, path: Path.users, minimumAllowedRole: Role.licenseAdmin, addDivider: true },
    {
        title: 'Global settings',
        icon: <SettingsIcon />,
        path: Path.settings,
        minimumAllowedRole: Role.super,
        addDivider: false,
    },
]
const bottomDrawerItems: DrawerItem[] = [{ title: 'Sign out', icon: <LogoutIcon />, path: Path.logout }]

function filterAllowedDrawerItems(role: Role, items: DrawerItem[]) {
    return items.filter(
        ({ minimumAllowedRole }) => !minimumAllowedRole || isRoleAuthorized({ role, minimumAllowedRole })
    )
}

export const AppLayout = () => {
    return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Drawer variant="permanent" sx={styles.drawer}>
                <SideBar />
            </Drawer>
            <Box component="main" style={{ flexGrow: 1 }}>
                <ComponentOfCurrentRoute />
            </Box>
        </Box>
    )
}

function SideBar() {
    const location = useLocation()
    const { user } = useAuth()
    const api = useBillingApi()
    const [buildInfo, setBuildInfo] = useState<BuildInfo | undefined>(undefined)
    useEffect(() => {
        void api.getBuildInfo().then((info) => {
            setBuildInfo(info)
        })
    }, [api])

    const userRole = user?.role ?? Role.invoiceOnly

    const currentDrawerItem: DrawerItem | undefined = [...topDrawerItems, ...bottomDrawerItems].find((item) =>
        location.pathname.startsWith(item.path)
    )
    const navigationState: NavigationState = { from: location }
    const makeDrawerItemUI = ({ title, icon, path, addDivider }: DrawerItem) => {
        return (
            <Fragment key={title}>
                {addDivider && <Divider />}
                <NavLink
                    to={path}
                    key={path}
                    state={navigationState}
                    style={{
                        textDecoration: 'none',
                        color: 'inherit',
                    }}
                >
                    <ListItemButton sx={styles.drawerItem} selected={path === currentDrawerItem?.path}>
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText primary={title} />
                    </ListItemButton>
                </NavLink>
            </Fragment>
        )
    }
    return (
        <div id="sidebar" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Toolbar>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="body2" component="div">
                        Billing
                    </Typography>
                    <div style={{ width: '33%', flexShrink: 0 }} />
                    <NavLink to={INITIAL_PATH} state={navigationState}>
                        <img style={{ width: '100%' }} src={netiLogoWhite} alt="Net Insight logo" />
                    </NavLink>
                </div>
            </Toolbar>
            <Divider />
            <List id="menu" disablePadding style={{ overflowY: 'auto' }}>
                {filterAllowedDrawerItems(userRole, topDrawerItems).map(makeDrawerItemUI)}
            </List>
            <Divider />
            <div
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                }}
            >
                <ListItem>
                    <ListItemText>
                        <Typography variant="body2" component="div" data-test-id="current-user">
                            {user?.username ?? 'Unknown user'}
                        </Typography>
                    </ListItemText>
                </ListItem>
                <Divider />
                {filterAllowedDrawerItems(userRole, bottomDrawerItems).map(makeDrawerItemUI)}

                <Typography variant="caption" style={{ padding: '0 16px 8px' }}>
                    {buildInfo?.release || ''}
                </Typography>
            </div>
        </div>
    )
}
