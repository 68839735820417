import { ReactNode, useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'

import { ListResult, SortOrder } from 'common/api/v1/types'
import { useDebounce } from './hooks/hook-debounce'
import { Query } from 'common/query'

type AutoCompleteProps<T> = {
    /// The label/placeholder text for the auto complete
    placeholder?: string
    api: (query: Query<string, SortOrder<any>>) => Promise<ListResult<T>>
    /// Groups the results by the returned string
    groupBy?: (searchResults: T) => string
    /// The selected value of the autocomplete
    value: T | null

    isRequired?: boolean

    /// Error message to show
    error?: string

    /// Callback invoked when user selects/clears a value
    onValueSelected: (value: T | null) => void
    // Used to fill the input field (i.e. the value in the text box) with a string value for the currently selected value
    formatSelectedValue: (value: T) => string
    // Used to render the available options
    renderOption?: (
        props: object,
        option: T,
        state: {
            selected: boolean
            inputValue: string
        }
    ) => ReactNode

    // If the entire component is disabled
    isDisabled?: boolean

    // If a single option is disabled or selectable
    isOptionDisabled?: (option: T) => boolean

    /// true to allow the user to clear the selected value
    isClearable: boolean

    dataTestId: string
}

export const AutoComplete = <T extends { id: string }>(props: AutoCompleteProps<T>) => {
    // The value displayed in the textbox.
    const [enteredText, setEnteredText] = useState('')
    const searchFilter = useDebounce(enteredText)

    // The list-result from the api call
    const [searchResults, setSearchResults] = useState<T[]>([])

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        let isMounted = true

        async function doFetch() {
            if (!isMounted) return
            setIsLoading(true)

            let searchResults: T[] | undefined = undefined
            try {
                searchResults = (await props.api({ skip: 0, limit: 1000, filter: searchFilter })).items
            } catch (error) {
                // eslint-disable-next-line
                console.error(`AutoComplete failed fetching`, error)
            }
            if (!isMounted) return
            if (searchResults !== undefined) setSearchResults(searchResults)
            setIsLoading(false)
        }

        void doFetch().catch()
        return () => {
            isMounted = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilter])

    return (
        <Autocomplete
            value={props.value}
            getOptionLabel={props.formatSelectedValue}
            renderOption={props.renderOption}
            onChange={(event, newValue) => {
                const didSelectSameValue = props.value?.id === newValue?.id
                if (didSelectSameValue) return
                props.onValueSelected(newValue)
            }}
            inputValue={enteredText}
            onInputChange={(event, newInputValue) => setEnteredText(newInputValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    margin={'normal'}
                    error={!!props.error}
                    helperText={props.error}
                    required={props.isRequired ?? false}
                    label={props.placeholder}
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        },

                        htmlInput: { ...params.inputProps, 'data-test-id': `${props.dataTestId}-autocomplete-input` },
                    }}
                />
            )}
            disabled={props.isDisabled ?? false}
            disableClearable={!props.isClearable}
            options={searchResults}
            loading={isLoading}
            groupBy={props.groupBy}
            getOptionDisabled={props.isOptionDisabled}
            autoHighlight
            fullWidth
            isOptionEqualToValue={(option: T, value: T) => option.id === value.id}
            data-test-id={`${props.dataTestId}-autocomplete`}
            data-num-options={searchResults.length}
        />
    )
}
